<template>
    <div class="quickCheckSetting-table-graderb">
        <div class="btn-con">
            <span class="btn" @click="addGrade">新增品级</span>
            <!--            <span class="btn" @click="choseGB">选择指标项</span>-->
            <span class="btn save-btn" @click="rbSave">保存</span>
        </div>
        <div class="table-con">
            <table>
                <tr>
                    <th style="width: 7%">
                        品级编码
                    </th>
                    <th style="width: 8%">
                        品级名称
                    </th>
                    <th style="width: 24%">
                        快检项目
                    </th>
                    <th>
                        指标
                    </th>
                    <th style="width: 15%">
                        操作
                    </th>
                </tr>
            </table>
            <div class="table-wrapper">
                <table>
                    <tr v-for="(gradeItem,gradeIndex) in tableData" :key="gradeIndex+gradeItem.grade_code">
                        <td style="width: 7%" class="gradeNametd">
                            {{ gradeItem.grade_code }}
                        </td>
                        <td style="width: 8%" class="gradeNametd">
                            {{ gradeItem.grade_name }}
                        </td>
                        <td colspan="2">
                            <table>
                                <tr v-for="(item,itemIndex) in gradeItem.indexMaterial" :key="gradeItem.grade_code+item.item_code">
                                    <td style="width: 35%">
                                        {{ item.item_name }}
                                    </td>
                                    <td style="width: 65%">
                                        <el-select
                                            placeholder=""
                                            v-model="item.symbol_min"
                                        >
                                            <el-option
                                                v-for="(option, optionIndex) in optionsMin"
                                                :key="optionIndex"
                                                :value="option.value"
                                            >
                                            </el-option>
                                        </el-select>
                                        <input class="custom-input el-input__inner" v-model="item.value_min">
                                        ~
                                        <el-select
                                            placeholder=""
                                            v-model="item.symbol_max"
                                        >
                                            <el-option
                                                v-for="(option, optionIndex) in optionsMax"
                                                :key=" optionIndex"
                                                :value="option.value"
                                            >
                                            </el-option>
                                        </el-select>
                                        <input class="custom-input el-input__inner" v-model="item.value_max">
                                        <span
                                            title="删除指标"
                                            class="iconbtn iconfont iconguanbi"
                                            @click="handleDeleteItem(gradeItem,itemIndex)"
                                        ></span>
                                    </td>
                                </tr>
                            </table>
                        </td>
                        <td style="width: 15%">
                            <div class="handle-box">
                                <span
                                    title="新增指标"
                                    class="iconbtn iconfont icontianjia"
                                    @click="choseGB(gradeItem)"
                                ></span>
                                <span
                                    title="修改品级"
                                    class="iconbtn iconfont iconbianji"
                                    @click="editGrade(gradeItem)"
                                ></span>
                                <span
                                    title="删除品级"
                                    class="iconbtn iconfont iconshanchu"
                                    @click="handleDeleteGrade(gradeItem)"
                                ></span>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <el-dialog
            :visible.sync="showGradeForm"
            :title="addDialogTitle"
            :close-on-click-modal="false"
            width="6rem"
        >
            <gradeForm
                v-if="showGradeForm"
                :current-data="currentData"
                :row="curentGrade"
                @handleSucc="handleSucc"
            ></gradeForm>
        </el-dialog>
    </div>
</template>

<script>
import util from '@/utils/util';
import gradeForm from './gradeForm';
export default {
    components: { gradeForm },
    props: {
        currentData: {
            type: [Object],
        },
    },
    data() {
        return {
            showGradeForm: false,
            addDialogTitle: '',
            // 当前操作的品级
            curentGrade: null,
            optionsMin: [
                {
                    value: '',
                    label: '',
                },
                {
                    value: '>',
                    label: '>',
                },
                {
                    value: '>=',
                    label: '>=',
                },
                {
                    value: '=',
                    label: '=',
                },
            ],
            optionsMax: [
                {
                    value: '',
                    label: '',
                },
                {
                    value: '<',
                    label: '<',
                },
                {
                    value: '<=',
                    label: '<=',
                },
                {
                    value: '=',
                    label: '=',
                },
            ],
            // 原始数据
            oldDataMapping: [],
            tableData: [],
            // 品级列表
            gradeList: [],
            rowTag: 0,
        };
    },
    watch: {
        currentData() {
            this.getData();
        },
    },
    created() {
        this.getData();
    },
    computed: {},
    methods: {
        getData() {
            this.getGradeList().then(() => {
                this.getRblist();
            });
        },
        // 新增品级
        addGrade() {
            this.addDialogTitle = '新增品级';
            this.showGradeForm = true;
            this.curentGrade = null;

            this.syscType = 1;
        },
        // 编辑
        editGrade(row) {
            this.addDialogTitle = '编辑品级';
            this.showGradeForm = true;
            this.curentGrade = row;

            this.syscType = 2;
        },
        handleSucc(gradeForm) {
            this.$message.success('操作成功');
            this.showGradeForm = false;
            this.getData();
            if (this.currentData.childs.length > 0) {
                this.asyncDataToChild(gradeForm);
            }
        },
        handleDelete(row, rowIndex) {
            if (row.id) {
                const ids = [];
                this.tableData.forEach(item => {
                    if (row.item_code === item.item_code) {
                        ids.push(item.id);
                    }
                });
                this.oldData.splice(rowIndex, this.gradeList.length);
                this.deleteRb(ids.join());
            }
            this.tableData.splice(rowIndex, this.gradeList.length);
        },
        // 删除指标
        deleteRb(ids) {
            this.$axios
                .delete('/interfaceApi/procurement/quality/config/indexmaterial/delete/' + ids)
                .then(res => {
                    if (res) {
                        this.$message.success('删除成功');
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },

        // 弹出国标列表
        choseGB(gradeItem) {
            this.curentGrade = gradeItem;
            const queryString = `type_dic_code=${this.currentData.type_dic_code}&`
                                + `child_type_code=${this.currentData.child_type_code}&`
                                + `child_type_dic_code=${this.currentData.child_type_dic_code}&`
                                + `spec_code=${this.currentData.spec_code}&`
                                + `spec_dic_code=${this.currentData.spec_dic_code}&`
                                + `grade_code=${gradeItem.grade_code}`;
            this.$toast({
                title: true,
                text: '请选择项目',
                type: 'eject',
                width: '12rem',
                height: '7rem',
                t_url: 'tableList/index',
                extr: {
                    code: {
                        TableCode: 'pjzbszxzzbx_pj',
                        QueryString: queryString,
                    },
                    selectTypeCallback: this.selectTypeCallback,
                },
            });
        },
        // 选择项目回调
        selectTypeCallback(data) {
            data.forEach(item => {
                const obj = {
                    id: '',
                    type_code: this.currentData.type_code,
                    type_dic_code: this.currentData.type_dic_code,
                    type_name: this.currentData.type_name,
                    child_type_code: this.currentData.child_type_code,
                    child_type_dic_code: this.currentData.child_type_dic_code,
                    child_type_name: this.currentData.child_type_name,
                    spec_code: this.currentData.spec_code,
                    spec_dic_code: this.currentData.spec_dic_code,
                    spec_name: this.currentData.spec_name,
                    item_code: item.item_code,
                    item_name: item.item_name,
                    symbol_min: '',
                    value_min: '',
                    symbol_max: '',
                    value_max: '',
                    detail: '',
                    remarks: '',
                    grade_code: this.curentGrade.grade_code,
                    grade_name: this.curentGrade.grade_name,
                    sort_num: 0,
                };
                this.curentGrade.indexMaterial.push(obj);
            });
            // this.$forceUpdate();
        },
        // 保存指标
        rbSave() {
            const editData = [];
            // 找出修改的数据，只提交修改或者新加得
            let index = 0;
            this.tableData.forEach(row => {
                row.indexMaterial.forEach(item => {
                    if (item.id) {
                        const bool = util.Compare(item, this.oldDataMapping[item.grade_code]);
                        if (!bool) {
                            editData.push(item);
                        }
                    } else {
                        editData.push(item);
                    }
                    index++;
                });
            });
            this.$axios
                .post('/interfaceApi/procurement/quality/config/indexmaterial/save', editData)
                .then(res => {
                    if (res) {
                        this.$message.success('保存成功');
                        this.getData();
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取指标列表
        async getRblist() {

            const params = {
                type_dic_code: this.currentData.type_dic_code,
                child_type_code: this.currentData.child_type_code,
                child_type_dic_code: this.currentData.child_type_dic_code,
                spec_code: this.currentData.spec_code,
                spec_dic_code: this.currentData.spec_dic_code,
            };
            const res = await this.$axios
                .post('/interfaceApi/procurement/quality/config/getindexmaterial', params);
            if (res) {
                const list = [];
                const mapping = {};
                res.forEach(item => {
                    list.push(...item.indexMaterial);
                    mapping[item.grade_code] = item.indexMaterial;
                });
                this.oldDataMapping = JSON.parse(JSON.stringify(mapping));
                const tableData = [];
                this.gradeList.forEach(item => {
                    const obj = Object.assign({}, item);
                    obj.indexMaterial = mapping[obj.grade_code] || [];
                    tableData.push(obj);
                });
                this.tableData = tableData;
            }
        },
        // 获取原材料品级
        async getGradeList() {
            const params = {
                type_dic_code: this.currentData.type_dic_code,
                child_type_code: this.currentData.child_type_code,
                child_type_dic_code: this.currentData.child_type_dic_code,
                spec_code: this.currentData.spec_code,
                spec_dic_code: this.currentData.spec_dic_code,
            };
            const res = await this.$axios
                .post('/interfaceApi/procurement/quality/config/gradematerial', params);
            if (res) {
                this.gradeList = res || [];
            }
        },
        // 指标下拉事件
        changeStandard(event, row, index) {
            this.$set(this.tableData, index, row);
        },

        // 删除品级
        handleDeleteGrade(gradeItem) {
            this.$confirm('此操作将删除该品级, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$axios
                    .delete('/interfaceApi/procurement/quality/config/gradematerial/delete/' + gradeItem.id)
                    .then(res => {
                        if (res) {
                            this.$message.success('删除成功!');
                            this.syscType = 3;
                            this.handleSucc(gradeItem);
                        }
                    })
                    .catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            });
        },
        // 同步数据到子集
        asyncDataToChild(gradeForm) {
            this.$confirm('是否同步原材料品级到子类、规格?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$axios
                    .post('/interfaceApi/procurement/quality/config/gradematerial/syncdata/' + this.syscType, gradeForm)
                    .then(() => {
                        this.$message.success('同步成功!');
                    })
                    .catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            }).catch(() => {});
        },
        // 删除指标
        handleDeleteItem(gradeItem, itemIndex) {
            const item = gradeItem.indexMaterial[itemIndex];
            this.$confirm('此操作将删除该指标项目, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(async () => {
                gradeItem.indexMaterial.splice(itemIndex, 1);
                if (item.id) {
                    await this.$axios.delete('/interfaceApi/procurement/quality/config/indexmaterial/delete/' + item.id);
                }
                this.$message.success('删除成功');
            });
        },
    },
    mounted() {},
};
</script>
<style lang="stylus">
.quickCheckSetting-table-graderb
    float left
    width calc(100% - 2.4rem)
    margin-left .2rem
    height 100%
    background #fff
    padding .1rem
    .btn-con
        .btn
            margin-right 0.1rem;
            padding .05rem .1rem
            text-align center
            background #1577D2
            border-radius .04rem
            display inline-block
            color #ffffff
            cursor pointer
            margin-bottom .1rem
    .table-con
        height calc(100% - .4rem)
        .table-wrapper
            width 100%;
            height calc(100% - 0.4rem)
            overflow-y auto
        .el-select
            width: 1rem;
            display: inline-block;
            margin-right: .05rem;
        .iconbtn
            color #D5000F
            cursor pointer
            margin 0.1rem
            &.icontianjia
                color #1577d2
            &.iconguanbi
                font-size 0.12rem;
                margin-left 0.1rem;
            /*&.iconbianji*/
            /*    color #1577d2*/
        .custom-input
            width 1rem
            height .36rem
            line-height .36rem
            margin-right .05rem
            text-align left!important
        .el-input__inner
            text-align right
        table
            width 100%;
            table-layout fixed
            border-collapse: collapse;
            font-size 0.14rem
            font-weight normal
            th
                background: #edf0f5;
                color: #022782;
                text-align: center;
                padding 0.08rem
            td
                text-align: center;
                border: 1px solid #ebeef5;
                white-space nowrap
                &.gradeNametd
                    padding: .08rem 0;
                table
                    th,td
                        padding: .08rem 0;
</style>
