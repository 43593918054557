<template>
    <div class="gradeForm">
        <el-form :model="gradeForm">
            <el-form-item label="品级编码" label-width="90px">
                <el-input v-model="gradeForm.grade_code" autocomplete="off" :readonly="gradeForm.id!=''"></el-input>
            </el-form-item>
            <el-form-item label="品级名称" label-width="90px">
                <el-input v-model="gradeForm.grade_name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="序号" label-width="90px">
                <el-input v-model="gradeForm.sort_num" autocomplete="off"></el-input>
            </el-form-item>
        </el-form>
        <div class="dialog-footer">
            <el-button @click="$parent.handleClose()">
                取 消
            </el-button>
            <el-button type="primary" @click="handleSave">
                保 存
            </el-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'grade-form',
    data() {
        return {
            addDialogTitle: '',
            gradeForm: {
                id: '',
                type_code: '',
                type_dic_code: '',
                type_name: '',
                child_type_code: '',
                child_type_dic_code: '',
                child_type_name: '',
                spec_code: '',
                spec_dic_code: '',
                spec_name: '',
                grade_code: '',
                grade_name: '',
                sort_num: 0,
            },
        };
    },
    props: {
        // 节点数据
        currentData: {
            type: [Object],
        },
        // 品级数据
        row: {
            type: [Object],
        },
    },
    created() {
        if (this.row) {
            this.editGrade(this.row);
        } else {
            this.addGrade();
        }
    },
    methods: {
        // 新增品级
        addGrade() {
            this.addDialogTitle = '新增品级';
            this.gradeForm.id = '';
            this.gradeForm.type_code = this.currentData.type_code;
            this.gradeForm.type_dic_code = this.currentData.type_dic_code;
            this.gradeForm.type_name = this.currentData.type_name;
            this.gradeForm.child_type_code = this.currentData.child_type_code;
            this.gradeForm.child_type_dic_code = this.currentData.child_type_dic_code;
            this.gradeForm.child_type_name = this.currentData.child_type_name;
            this.gradeForm.spec_code = this.currentData.spec_code;
            this.gradeForm.spec_dic_code = this.currentData.spec_dic_code;
            this.gradeForm.spec_name = this.currentData.spec_name;
            this.gradeForm.grade_code = '';
            this.gradeForm.grade_name = '';
            this.gradeForm.sort_num = 0;
        },
        // 编辑
        editGrade(row) {
            this.addDialogTitle = '编辑品级';
            this.gradeForm.id = row.id;
            this.gradeForm.type_code = row.type_code;
            this.gradeForm.type_dic_code = row.type_dic_code;
            this.gradeForm.child_type_dic_code = row.child_type_dic_code;
            this.gradeForm.type_name = row.type_name;
            this.gradeForm.child_type_code = row.child_type_code;
            this.gradeForm.child_type_name = row.child_type_name;
            this.gradeForm.spec_code = row.spec_code;
            this.gradeForm.spec_dic_code = row.spec_dic_code;
            this.gradeForm.spec_name = row.spec_name;
            this.gradeForm.grade_code = row.grade_code;
            this.gradeForm.grade_name = row.grade_name;
            this.gradeForm.sort_num = row.sort_num;
        },
        // 保存数据
        handleSave() {
            this.$axios
                .post('/interfaceApi/procurement/quality/config/gradematerial/save', this.gradeForm)
                .then(res => {
                    if (res) {
                        this.$emit('handleSucc', this.gradeForm);
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },

    },
};
</script>

<style lang="stylus" scoped>
.gradeForm{
    .dialog-footer{
        width 100%
        display flex
        justify-content center
        padding-bottom 0.2rem
        .el-button{
            margin 0 0.2rem
        }
    }
}
</style>
